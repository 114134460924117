<template>
  <CDropdown color="light">
    <template #toggler-content>
      <CIcon name="cil-tag" />
      <CBadge class="ml-1" :color="selectedLabel.color">{{ selectedLabel.label }}</CBadge>
    </template>

    <CDropdownItem v-for="(el, index) in $globalVar.emailLabels" :key="index" @click="() => taskLabel = el.value">
      <CBadge :color="el.color">{{ el.label }}</CBadge>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TaskLabel",
  props: {
    label: {
      type: String,
      default: () => 'waiting',
    },
  },
  computed: {
    taskLabel: {
      get() {
        return this.label;
      },
      set(newValue) {
        if (newValue === this.label) return;

        this.$emit("update:label", newValue);
        this.$emit("change");
      },
    },
    selectedLabel() {
      return this.$globalVar.emailLabels.find((el) => el.value === this.taskLabel);
    },
  }
};
</script>
