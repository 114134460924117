<template>
  <CSidebar
    aside
    :show="$store.state.taskActivityLogsAsideShow"
    @update:show="(val) => $store.commit('set', ['taskActivityLogsAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'taskActivityLogsAsideShow')" />

    <!-- Error Alert -->
    <CListGroup class="list-group-accent mt-5" v-show='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>
    <!-- / Error Alert -->

    <CListGroup class="scrollable list-group-accent mt-5" v-show='!showErrorAlert'>
      <CListGroupItem class="mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Change Task Status
      </CListGroupItem>
      <CListGroupItem class="list-group-item-accent-dark">
        <TaskLabel :label.sync="task.label"></TaskLabel>
      </CListGroupItem>

      <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Activity Logs
      </CListGroupItem>

      <template v-for="activity in logs">
        <CListGroupItem class="list-group-item-divider" :class="`list-group-item-accent-${activity.new_label?.color}`">
          <div>{{ activity?.description }}</div>
          <div class="small text-muted mt-1">
            <CIcon name="cil-tag" class="mr-2" />
            {{ activity.old_label?.label }}
            <CIcon name="cil-arrow-right" class="mx-2"/>
            {{ activity.new_label?.label }}
          </div>
          <div class="small text-muted mt-1">
            <CIcon name="cil-calendar" class="mr-2" />
            {{ moment(activity.created_at).calendar() }}
          </div>
        </CListGroupItem>
      </template>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading || submitted"/>

  </CSidebar>
</template>

<script>
import TaskLabel from './TaskLabelDD.vue';

export default {
  name: "TaskActivityLogs",
  props: {
    task: {
      type: Object,
      default: null
    },
  },
  components: {
    TaskLabel,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      logs: [],

      errorAlertMessage: '',
      showErrorAlert: false,
    };
  },
  watch: {
    async reloadParams() {
      this.$store.state.taskActivityLogsAsideShow && await this.getTaskActivityLogs();
    },

    "task.label": async function (newVal, oldVal) {
      oldVal && this.$store.state.taskActivityLogsAsideShow && await this.changeLabel(oldVal);
    },
  },
  computed: {
    reloadParams() {
      return [this.$store.state.taskActivityLogsAsideShow];
    },
  },

  methods: {
    async getTaskActivityLogs() {
      this.logs = [];
      this.loading = true;
      await this.$axios.get(this.$backend.ACTIVITIES.GET_BY_PROPERTY, {
          params: {
            task_id: this.task.taskID,
            'subject-id': this.task.id
          }
        })
        .then((response) => {
          this.logs = response.data.map((el) => {
            return {
              description: el.description,
              old_label: this.getLabel(el.properties.old_label),
              new_label: this.getLabel(el.properties.new_label),
              created_at: el.created_at,
              // properties: JSON.parse(el.properties),
            };
          })
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data.message
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLabel(value) {
      return value ? this.$globalVar.emailLabels.find((el) => el.value === value) : null;
    },


    async changeLabel(oldLabel) {
      this.submitted = true;
      await this.$axios.put(this.$backend.CRM_TASKS.UPDATE.replace("{id}", this.task.taskID), {
          'old-label': oldLabel,
          'new-label': this.task.label,
        })
        .then((response) => {
          this.$store.commit('set', ['taskActivityLogsAsideShow', false]);
          this.task.isChanged = true;
          this.$toast.success(`${response.data.message}`);
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>
