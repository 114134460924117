<template>
  <div>
    <CDataTable v-if="allTasks.length > 0"
      class="tasks-table table-outline"
      hover
      :items="allTasks"
      :fields="fields"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results available!',
        noItems: 'No task found!',
      }"
      head-color="light"
      no-sorting
    >
      <td slot="assigned_by" slot-scope="{item}">
        <div class="d-flex align-items-center">
          <div class="c-avatar mr-2" v-c-tooltip="{ content: '#' + item.from.id }">
            <img :src="item.from.image_icon || item.from.gravatar" class="c-avatar-img" alt="">
          </div>
          <div>
            <span>{{item.from.first_name}}</span>
            <span class="d-block small text-muted">{{ moment(item.created_at).calendar() }}</span>
          </div>
        </div>
      </td>

      <td slot="title" slot-scope="{item}">
        <p class="h6">
          {{ item.subject }}
          <CLink
            :to="{ name: 'Message', params: { id: item.task_id, folder: 'inbox' }, query: { user: item.user_id } }"
            v-c-tooltip="{content : 'Show Thread'}"
          ><CIcon name="cil-at" /></CLink>
        </p>

        <div v-if="item.restaurant" class="d-flex align-items-center">
          <CIcon name="cil-restaurant" />
          <CLink :to="{name: 'View Restaurant', params: { id: item.restaurant.id }}" class="ml-1 small text-muted">{{ item.restaurant?.restaurant_name }}</CLink>
        </div>
      </td>

      <td slot="restaurant" slot-scope="{item}">
        <div class="d-flex align-items-center">
          <div class="c-avatar mr-2">
            <img :src="item.restaurant?.restaurant_logo_path" class="c-avatar-img" alt="">
          </div>
          <CLink :to="{name: 'View Restaurant', params: { id: item.restaurant?.id }}" class="small text-muted">{{ item.restaurant?.restaurant_name }}</CLink>
        </div>
      </td>

      <td slot="label" slot-scope="{item}">
        <div class="clearfix">
          <div class="float-left">
            <strong>{{ item.labelObj.label }}</strong>
          </div>
          <div class="float-right">
            <small class="text-muted">{{ moment(item.updated_at).calendar() }}</small>
          </div>
        </div>
        <CProgress
          class="progress-xs"
          :color="item.labelObj.color"
          :value="item.labelObj.progress"
        />
      </td>

      <td slot="assigned_to" slot-scope="{item}">
        <div class="d-flex align-items-center">
          <div class="c-avatar mr-2" v-c-tooltip="{ content: '#' + item.user.id }">
            <img :src="item.user.image_icon || item.user.gravatar" class="c-avatar-img" alt="">
          </div>
          <span class="small text-muted">{{ item.user?.first_name }}</span>
        </div>
      </td>

      <td slot="last_activity" slot-scope="{item}">
        <div class="d-flex">
          <!-- <strong role="button" @click="showTaskActivityLogs(item)"><CIcon name="cil-fingerprint" size="xl" /></strong> -->
          <div v-if="item.last_activity">
            <div class="small text-muted">{{ item.last_activity.event.slugToTitle() }}</div>
            <strong role="button" @click="showTaskActivityLogs(item)">{{ moment(item.last_activity.created_at).fromNow() }}</strong>
          </div>
          <span v-else class="small text-muted">-No activity-</span>
        </div>
      </td>

      <!-- Actions -->
      <template #actions="{item, index}">
        <td class="text-left">
          <CButton
            size="sm"
            color="dark"
            variant="ghost"
            shape="pill"
            v-c-tooltip="{
              placement: 'left',
              content: 'Change Task Status'
            }"
            @click="showTaskActivityLogs(item)"
          >
            <CIcon name="cil-fingerprint" size="xl" />
          </CButton>

          <CButton
            v-if="item.is_editable === true"
            size="sm"
            color="info"
            variant="ghost"
            shape="pill"
            @click="showEditTask(item)"
          >
            <CIcon name="cil-align-left" />
          </CButton>

          <template v-if="item.is_deletable === true">
            <CSpinner v-if="item.deleting" size="sm" color="danger" class="mx-2" />
            <CButton
              v-else
              size="sm"
              color="danger"
              variant="ghost"
              shape="pill"
              @click="deleteItem(item, index)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </template>
        </td>
      </template>

    </CDataTable>

    <div v-else>
      <CSpinner v-if="loading" />
      <h6 v-else>No task found!</h6>
    </div>

    <EditTask :task.sync="selectedTask" />
    <TaskActivityLogs :task.sync="selectedTask" />
  </div>
</template>

<script>
import EditTask from "./EditTask";
import TaskActivityLogs from "./TaskActivityLogs";

export default {
  name: "TaskTable",
  components: {
    EditTask,
    TaskActivityLogs,
  },
  props: {
    tasks: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedTask: {},
      fields: [
        { key: "assigned_by" },
        { key: "title" },
        { key: "label", label: "Status" },
        { key: "assigned_to" },
        { key: "last_activity" },
        { key: "actions", label: "", _style: "min-width: 135px; width: 135px;" },
      ],
    };
  },
  computed: {
    allTasks: {
      get() { return this.tasks; },
      set(newValue) { this.$emit("update:tasks", newValue); },
    },
  },
  watch: {
    "selectedTask.isChanged": {
      handler: function (val) {
        val && this.$emit("taskChanged");
      },
      deep: true,
    },
  },

  methods: {
    showEditTask(task) {
      this.selectedTask = {
        id      : task.id,
        from_id : task.from_id,
        taskID  : task.task_id,
        subject : task.subject,
        label   : task.label,
        assigned_name: task.user.first_name,
        isChanged: false,
      };
      this.$store.commit('set', ['editTaskAsideShow', true]);
    },
    showTaskActivityLogs(task) {
      this.selectedTask = {
        id      : task.id,
        taskID  : task.task_id,
        label   : task.label,
        isChanged: false,
      };
      this.$store.commit('set', ['taskActivityLogsAsideShow', true]);
    },
    async deleteItem(item, index) {

      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to delete?",
        text: "The task and all related e-mail will be deleted!",
        icon: "warning",
        input: "checkbox",
        inputValue: 0,
        inputPlaceholder: "Also delete duplicate tasks assigned to other admins"
      });


      if (confirm.isConfirmed) {
        const deleteUrl = this.$backend.CRM_TASKS.DELETE.replace("{id}", item.task_id);
        item.deleting = true;

        try {
          const { data } = await this.$axios.delete(deleteUrl, {
            data: {
              'duplicate': confirm.value,
              'id': item.id,
            }
          });

          data.deleted_ids.forEach(id => {
            const index = this.allTasks.findIndex(task => task.id === id);
            index !== -1 && this.$delete(this.allTasks, index);
          });

        } catch (error) {
          item.deleting = false;

          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        } finally {
          this.$forceUpdate();
        }
      }
    }
  },
};
</script>

<style>
.tasks-table table {
  margin-bottom: 0;
}

.tasks-table table th, .tasks-table table td {
  padding: 0.75rem;
  vertical-align: middle;
}
</style>
