<template>
  <CSidebar
    aside
    :show="$store.state.editTaskAsideShow"
    @update:show="(val) => $store.commit('set', ['editTaskAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'editTaskAsideShow')" />

    <!-- Error Alert -->
    <CListGroup class="list-group-accent mt-5" v-show='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>
    <!-- / Error Alert -->

    <CListGroup class="scrollable list-group-accent mt-5" v-show='!showErrorAlert'>
      <CListGroupItem class="mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Edit Task
      </CListGroupItem>

      <CListGroupItem class="list-group-item-divider list-group-item-accent-dark">
        <div class="form-group mb-0">
          <label class="d-block">Change Task Status:</label>
          <TaskLabel :label.sync="task.label"></TaskLabel>
        </div>
      </CListGroupItem>


      <CListGroupItem class="list-group-item-divider list-group-item-accent-primary">
        <CForm @submit.prevent="update">
          <CInput
            class="compose-input"
            label="Subject:"
            placeholder="Subject"
            :value.sync="task.subject"
            :lazy="false"
            was-validated
            required
          />

          <div class="form-group">
            <label class="d-block">New Recipients:</label>
            <v-select
              label="first_name"
              placeholder="Select.."
              v-model="recipients"
              multiple
              :options="users"
              :loading="loading"
            >
              <template v-slot:option="user">
                <span>{{ `${user.first_name}` }}</span>
              </template>
            </v-select>
          </div>

          <div v-if="recipients.length" class="form-group reply">
            <label class="d-block">Message:</label>
            <quill-editor
              :options="editorOption"
              v-model="email.reply"
            />

            <div class="mt-2 p-1 bg-danger text-light">
              <CInputCheckbox
                custom
                :label="`Delete the task from ${task.assigned_name}`"
                :checked.sync="deleteSelf"
              />
            </div>
          </div>

          <CButton
            type="submit"
            color="primary"
            :disabled="submitted"
          >
            <CIcon name="cil-send" /> Submit
          </CButton>
        </CForm>
      </CListGroupItem>


    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading || submitted"/>

  </CSidebar>
</template>

<script>
import TaskLabel from './TaskLabelDD.vue';
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "EditTask",
  props: {
    task: {
      type: Object,
      default: null
    },
  },
  components: {
    TaskLabel,
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        placeholder: 'Write your message here',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['code-block'],
          ],
        }
      },
      loading: false,
      submitted: false,
      errorAlertMessage: '',
      showErrorAlert: false,

      users: [],
      recipients: [],
      email: { subject: null, reply: null },
      deleteSelf: false,
    };
  },
  watch: {
    async reloadParams() {
      this.loading = true;
      if (this.$store.state.editTaskAsideShow) {
        this.recipients = [];
        await this.getMessage();
        await this.getAdminUsers();
      }
      this.loading = false;
    },

    "task.label": async function (newVal, oldVal) {
      oldVal && this.$store.state.editTaskAsideShow && await this.updateLabel(oldVal);
    },

    recipients(newVal, oldVal) {
      if (newVal.length == 0) {
        this.deleteSelf = false;
      }
    },
  },
  computed: {
    reloadParams() {
      return [this.$store.state.editTaskAsideShow];
    },
  },

  methods: {
    getLabel(value) {
      return value ? this.$globalVar.emailLabels.find((el) => el.value === value) : null;
    },
    async getAdminUsers() {
      await this.$axios
        .get(this.$backend.USERS.GET_ADMIN_USERS, {
          params: { fields: "email", "exclude-user": "current" },
        })
        .then((response) => {
          this.users = response.data;
        });
    },
    async getMessage() {
      this.loading = true;
      await this.$axios
        .get(this.$backend.CRM_EMAILS.SHOW.replace("{id}", this.task.taskID), {
          params: {
            "user-id": this.task.from_id,
          },
        })
        .then((response) => {
          this.email = response.data;
        })
        .catch((error) => {
        })
        .finally(() => {
          this.loading = false;
        });
    },


    async updateLabel(oldLabel) {
      this.submitted = true;
      this.$axios.put(this.$backend.CRM_TASKS.UPDATE.replace("{id}", this.task.taskID), {
          'old-label': oldLabel,
          'new-label': this.task.label,
        })
        .then((response) => {
          this.task.isChanged = true;
          this.$toast.success(`${response.data.message}`);
        })
        .finally(() => {
          this.task.isChanged = false;
          this.submitted = false;
        });
    },

    async update() {
      const updateData = {
        'subject': this.task.subject,
        'new-recipients': this.recipients.pluck('id'),
      };

      if (this.recipients.length) {
        updateData['message'] = this.email.reply;
        if (this.deleteSelf) {
          updateData['delete'] = { "id": this.task.id };
        }
      }

      this.submitted = true;
      await this.$axios.put(this.$backend.CRM_TASKS.UPDATE.replace("{id}", this.task.taskID), updateData)
        .then((response) => {
          this.$store.commit('set', ['editTaskAsideShow', false]);
          this.task.isChanged = true;
          this.$toast.success(`${response.data.message}`);
        })
        .finally(() => {
          this.task.isChanged = false;
          this.submitted = false;
        });
    },
  }
};
</script>

<style>
.compose-input > input {
  border: 1px solid rgba(60, 60, 60, 0.26) !important;
  border-radius: 0.25rem;
}
.reply .ql-editor {
  min-height: 240px;
}
</style>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>
